<template>
    <div class="month_form_detail">
        <el-dialog title="项目月报详情" :visible.sync="show" append-to-body :close-on-click-modal="false" width="900px" class="month_form_detail_dialog">
            <div class="detail">
                <div class="content">
                    <div class="title">月报基础信息</div>
                    <div class="detail_item items">
                        <div class="item long">
                            <div class="left">项目进展情况</div>
                            <div class="right" v-html="detail.new_project_evolve"></div>
                        </div>
                        <div class="item">
                            <div class="left">项目状态</div>
                            <div class="right">{{initProjectStatus(detail.project_status)}}</div>
                        </div>
                        <!--<div class="item" v-if="detail.project_start_time">
                            <div class="left">建设周期</div>
                            <div class="right">{{detail.project_start_time.slice(0,4)}}年{{detail.project_start_time.slice(5,7)}}月-{{detail.project_end_time.slice(0,4)}}年{{detail.project_end_time.slice(5,7)}}月</div>
                        </div>-->
                        <div class="item" v-if="detail.month">
                            <div class="left">填报月度</div>
                            <div class="right">{{detail.month.slice(0,4)}}年{{detail.month.slice(5,7)}}月</div>
                        </div>
                        <div class="item">
                            <div class="left">实施情况(前期)</div>
                            <div class="right" v-if="detail.implementation_before">{{detail.implementation_before.join('、')}}</div>
                        </div>
                        <div class="item">
                            <div class="left">实施情况(进度)</div>
                            <div class="right">
                                <el-progress :stroke-width="12" :percentage="initImplementationRate(detail.implementation_rate)?initImplementationRate(detail.implementation_rate):0" :color="customColorMethod"></el-progress>
                            </div>
                        </div>
                        <div class="item">
                            <div class="left">实施情况(后期)</div>
                            <div class="right" v-if="detail.implementation_after">{{detail.implementation_after.join('、')}}</div>
                        </div>
                        <div class="item">
                            <div class="left">年度项目进展率</div>
                            <div class="right">
                                <el-progress :stroke-width="12" :percentage="detail.year_project_evolve_rate" :color="customColorMethod"></el-progress>
                            </div>
                        </div>
                        <div class="item long">
                            <div class="left">项目状况概述</div>
                            <div class="right" v-html="detail.new_project_status_des"></div>
                        </div>
                        <div class="item long">
                            <div class="left">项目进展附件</div>
                            <div class="right file_box">
                                <div class="file" v-for="(item,index) in detail.project_file_ids" :key="index">
                                    <div class="text"><span @click="previewFile(item)">{{item.fileName}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="item long" v-if="JSON.stringify(moneyInfo)!=='{}'&&((moneyInfo.month1==detail.month)||(moneyInfo.month2==detail.month))">
                            <div class="left">已确认资金</div>
                            <div class="right">
                                <div v-if="moneyInfo.month1==detail.month">第一次拨款{{Number(moneyInfo.money1)/10000}}万元</div>
                                <div v-if="moneyInfo.month2==detail.month">第二次拨款{{Number(moneyInfo.money2)/10000}}万元</div>
                            </div>
                        </div>
                    </div>
                    <div class="detail_item table" v-if="payment.length>0">
                        <div class="title">项目拨付款</div>
                        <el-table :data="payment">
                            <el-table-column type="index" label="序号" align="center"></el-table-column>
                            <el-table-column label="拨付类型" align="center">
                                <template slot-scope="scope">
                                    <span>{{initPaymentType(scope.row.type)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="拨付金额" align="center">
                                <template slot-scope="scope">
                                    {{Number(scope.row.app_money)/10000}}万元
                                </template>
                            </el-table-column>
                            <el-table-column property="app_object" label="拨付对象" align="center"></el-table-column>
                            <el-table-column label="拨付日期" align="center">
                                <template slot-scope="scope">
                                    <span>{{scope.row.app_data.slice(0,10)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <paymentDetail :app_id="scope.row.app_id"></paymentDetail>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!--<div class="detail_item files">
                        <div class="title">项目管理</div>
                        <div class="files_items">
                            <div class="item" v-for="(item,index) in fileList" :key="index">
                                <div class="left">{{initFileList(item.value)}}</div>
                                <div class="right">
                                    <span>{{item.files.fileName||''}}</span>
                                    <el-button type="text" style="margin-left: 5px;" @click="updateFile(item)">点击上传附件</el-button>
                                </div>
                            </div>
                        </div>
                    </div>-->
                </div>
                <!--<div class="button">
                    <el-button size="small" class="button_88px" type="primary" @click="addProjectManage">保存</el-button>
                </div>-->
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    import computedData from '@/mixins/computedData.js';
    import paymentDetail from '@c/project/payment/payment_detail.vue';
    import dingDisk from '@/utils/ding_disk';
    import customColor from '@/mixins/customColor.js'
    export default {
        mixins:[computedData,customColor],
        components:{paymentDetail},
        data(){
            return{
                show:false,form_id:'',detail:{},
                payment:[],//拨付款列表
                /*fileList:[
                    {value:'before', files:{}},
                    {value:'process', files:{}},
                    {value:'check', files:{}},
                    {value:'monthly', files:{}},
                    {value:'log', files:{}},
                ],*/
                moneyInfo:{}
            }
        },
        methods:{
            detailForm(){
                this.detail={};
                api.project.detailForm({form_id:this.form_id}).then(res=>{
                    if(res.data.state==1){
                        if(res.data){
                            this.detail=res.data.rows||{};
                            if(this.detail.project_file_ids){
                                this.detail.project_file_ids=JSON.parse(this.detail.project_file_ids);
                            }else{
                                this.detail.project_file_ids=[];
                            }
                            if(isNaN(this.detail.year_project_evolve_rate)){
                                this.detail.year_project_evolve_rate=0
                            }else{
                                this.detail.year_project_evolve_rate=Number(this.detail.year_project_evolve_rate)
                            }
                            if(this.detail.implementation_before){
                                let implementation_before=this.detail.implementation_before.split(',');
                                this.detail.implementation_before=implementation_before.map(item=>this.initImplementationBefore(item));
                            }else{
                                this.detail.implementation_before=[];
                            }
                            if(this.detail.implementation_rate){
                                this.detail.implementation_rate=Number(this.detail.implementation_rate);
                            }
                            if(this.detail.implementation_after){
                                let implementation_after=this.detail.implementation_after.split(',');
                                this.detail.implementation_after=implementation_after.map(item=>this.initImplementationAfter(item));
                            }else{
                                this.detail.implementation_after=[];
                            }
                            let regN = /\n/g;
                            let regR = /\r/g;
                            //项目进展情况
                            if (this.detail.project_evolve) {
                                this.detail.new_project_evolve = this.detail.project_evolve.replace(regN, "<br />").replace(regR, "<br />");
                            }
                            //项目状况概述
                            if (this.detail.project_status_des) {
                                this.detail.new_project_status_des = this.detail.project_status_des.replace(regN, "<br />").replace(regR, "<br />");
                            }
                           /* if(this.detail.project_management){
                                this.fileList=JSON.parse(this.detail.project_management)
                            }else{
                                this.fileList=[
                                    {value:'before', files:{}},
                                    {value:'process', files:{}},
                                    {value:'check', files:{}},
                                    {value:'monthly', files:{}},
                                    {value:'log', files:{}},
                                ]
                            }*/
                            this.appLists();
                            this.fundList();
                        }
                    }
                })
            },
            appLists(){
                this.payment=[];
                api.project.appLists({
                    // form_id:this.detail.form_id,
                    page:1,
                    pagesize:200,
                    month:this.detail.month,
                    project_id:this.detail.project_id
                }).then(res=>{
                    if(res.data){
                        this.payment=res.data.lists||[];
                    }
                })
            },
            //上传附件
            // updateFile(item){
            //     dingDisk.dingDiskUpdate(res=>{
            //         item.files=res.data[0];
            //     },1)
            // },
            //预览附件
            previewFile(file_info){
                dingDisk.previewFile(file_info)
            },
            //项目管理附件上传
            /*addProjectManage(){
                for(let i=0;i<this.fileList.length;i++){
                    if(!this.fileList[i].files.fileId){
                        this.$message.error('项目管理有未上传的附件');
                        return;
                    }
                }
                let param={
                    form_id:this.detail.form_id,
                    project_id:this.detail.project_id,
                    project_status:this.detail.project_status,
                    // project_start_time:this.detail.project_start_time,
                    // project_end_time:this.detail.project_end_time,
                    implementation_before:this.detail.implementation_before,
                    implementation_rate:this.detail.implementation_rate,
                    implementation_after:this.detail.implementation_after,
                    project_evolve:this.detail.project_evolve,
                    year_project_evolve_rate:this.detail.year_project_evolve_rate,
                    project_status_des:this.detail.project_status_des,
                    month:this.detail.month
                }
                if(this.detail.project_file_ids){
                    if(this.detail.project_file_ids.length>0){
                        param.project_file_ids=JSON.stringify(this.detail.project_file_ids)
                    }
                }
                // param.project_management=JSON.stringify(this.fileList);
                api.project.addForm(param).then(res=>{
                    if(res.data.state==1){
                        this.$message({
                            message: `月报添加项目管理附件成功`,
                            type: 'success'
                        });
                        this.show=false;
                    }
                })
            },*/
            fundList(){
                api.money.fundLists({project_id:this.detail.project_id}).then(res=>{
                    if(res.data&&res.data.lists){
                        if(res.data.lists.length>0){
                            this.moneyInfo=res.data.lists[0];
                        }
                    }
                })
            },
        },
        watch:{
            show:{
                handler:function(val){
                    if(val){
                        this.detailForm();
                    }
                },
                immediate:true
            }
        }
    }
</script>

<style lang="less">
.month_form_detail_dialog{
    .files_items{
        display:flex;
        flex-wrap: wrap;
        >.item{
            flex:0 0 50%;
            display:flex;
            align-items: center;
            .left{
                flex:0 0 100px;
                text-align: right;
                padding:0 10px;
                background:#f8fbff;
                line-height: 40px;
                height: 40px;
                box-shadow: 0 1px #e4e7ec, 0 1px inset #e4e7ec;
            }
            .right{
                flex:1;
                padding:0 10px;
                border-left: 1px solid #e4e7ec;
                border-right: 1px solid #e4e7ec;
                box-shadow: 0 1px #e4e7ec, 0 1px inset #e4e7ec;
            }
        }
    }
    .file{
        padding-bottom: 10px;
        .text:hover{
            color:#333;
            cursor: default;
        }
        span:hover{
            cursor: pointer;
            color:#3396fb;
        }
    }
}
</style>
<style>
    .el-progress__text{
        font-size: 14px!important;
    }
</style>