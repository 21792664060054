<template>
    <div class="edit_child_project">
        <el-popover placement="left-end" width="280" trigger="click" :visible-arrow="false" v-model="show" @after-leave="show=false" popper-class="child_popover">
            <div class="content">
                <div class="header">
                    <div class="icon" @click="show=false">
                        <img class="default" src="../../../assets/img/del.png" alt="">
                        <img class="hover" src="../../../assets/img/del_hover.png" alt="">
                    </div>
                    <h4>编辑子项目</h4>
                </div>
                <el-form :model="ruleFormChild" :rules="rulesChild" ref="ruleFormChild" label-position="top" class="child_form">
                    <div class="body">
                        <el-form-item label="标题" prop="child_name">
                            <el-input v-model="ruleFormChild.child_name" size="small" placeholder="请输入子项目标题"></el-input>
                        </el-form-item>
                        <el-form-item label="投资金额" prop="child_all_invest_num">
                            <el-input-number v-model="ruleFormChild.child_all_invest_num" size="small" :step="1" :controls="false" placeholder="请输入子项目投资金额"></el-input-number>
                        </el-form-item>
                        <el-form-item :label="year+'年投资金额'" prop="child_year_invest_num" v-for="(year,index) in years" :key="index">
                            <el-input-number v-model="ruleFormChild.child_year_invest_num[year]" size="small" :step="1" :controls="false" placeholder="请输入子项目年投资金额" @change="$refs.ruleFormChild.validateField('child_year_invest_num')"></el-input-number>
                        </el-form-item>
                    </div>
                    <div class="button">
                        <el-button class="button_78px" size="mini" type="primary" @click="submitForm">保存</el-button>
                    </div>
                </el-form>
            </div>
            <el-button slot="reference" type="text">编辑</el-button>
        </el-popover>
    </div>
</template>

<script>
    export default {
        props:{
            years:{
                type:Array,
                require:true
            },
            parent_form:{
                type:Object,
                require:true
            },
            child_form:{
                type:Object,
                require:true
            },
            index:{
                type:Number,
                require:true
            }
        },
        data(){
            const check_all_invest_num = (rule, value, callback) => {
                if(value===0){
                    callback(new Error('投资金额不能为0'));
                }else{
                    callback();
                }
            };
            const check_year_invest_num = (rule, value, callback) => {
                if(value){
                    let arr = [];
                    let years=[];
                    for(let val in value){
                        years.push(val);
                        if(value[val]!==undefined&&value[val]!==null&&value[val]!=='') arr.push(value[val])
                    }
                    if(arr.length===0){
                        callback(new Error('请输入年投资金额'));
                    }
                    if(arr.length!==years.length){
                        callback(new Error('请输入每年投资金额'));
                    }
                    callback();
                }else{
                    callback();
                }
            };
            return{
                show:false,
                ruleFormChild:JSON.parse(JSON.stringify(this.child_form)),
                rulesChild:{
                    child_name: [{ required: true, message: '请输入子项目标题', trigger: 'blur'},],
                    child_all_invest_num:[
                        { required: true, message: '请输入子项目投资金额', trigger: 'blur' },
                        // {validator:check_all_invest_num, trigger: 'blur'}
                    ],
                    child_year_invest_num:[
                        { required: true, message: '请输入子项目年投资金额', trigger: 'blur' },
                        // {validator:check_year_invest_num, trigger: 'blur'}
                    ],
                },
            }
        },
        methods:{
            submitForm(){
                this.$refs.ruleFormChild.validate((valid) => {
                    if (valid) {
                        this.parent_form.child_project_info.splice(this.index,1,JSON.parse(JSON.stringify(this.ruleFormChild)));
                        this.show=false;
                    }
                });
            },
        },
        watch:{
            show:{
                handler:function(val){
                    if(val){
                        this.ruleFormChild=JSON.parse(JSON.stringify(this.child_form));
                        this.$refs.ruleFormChild.resetFields();
                    }
                },
                immediate:true
            }
        }
    }
</script>

<style scoped lang="less">
    div.edit_child_project{
        display:inline-block;
    }
</style>