<template>
    <div class="add_project">
        <div class="back" @click="$emit('back')">
            <img class="default" src="../../assets/img/back.png" alt="">
            <img class="hover" src="../../assets/img/back_hover.png" alt="">
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="parent_form">
            <div class="row">
                <div class="item long">
                    <el-form-item label="项目名称" prop="project_name">
                        <el-input v-model="ruleForm.project_name" placeholder="请输入项目名称"></el-input>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="项目所属区域" prop="area">
                        <el-select size="medium" v-model="ruleForm.area" popper-class="global_select_popper"
                                   :disabled="(enter==1&&shi_project_project_power.area>0)||(enter==2&&statistics_power.area>0)">
                            <el-option v-for="item in areaList" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="项目所在地" prop="address">
                        <el-input v-model="ruleForm.address" placeholder="请输入项目所在地"></el-input>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="总投资金额" prop="all_invest_num">
                        <el-input-number v-model="ruleForm.all_invest_num" :step="1" :controls="false"
                                         placeholder="请输入总投资金额"></el-input-number>
                        <div class="unit">万元</div>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="实际投资金额" prop="real_num">
                        <el-input-number v-model="ruleForm.real_num" :step="1" :controls="false"
                                         placeholder="请输入实际投资金额"></el-input-number>
                        <div class="unit">万元</div>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="建设周期" prop="time">
                        <el-date-picker v-model="ruleForm.time" type="monthrange" range-separator="-"
                                        start-placeholder="选择日期" end-placeholder="选择日期"
                                        :clearable="false" value-format="yyyy-MM" @change="timeChange"></el-date-picker>
                    </el-form-item>
                </div>
                <!-- <div class="item">
                     <el-form-item label="立项前期费用">
                         <el-input-number v-model="ruleForm.project_brfore_num" :step="1" :controls="false" placeholder="请输入立项前期费用"></el-input-number>
                         <div class="unit">万元</div>
                     </el-form-item>
                 </div>-->
                <div class="item" v-for="(year,index) in years" :key="index">
                    <el-form-item :label="year+'年投资金额'" prop="year_invest_num">
                        <el-input-number v-model="ruleForm.year_invest_num[year]" :step="1" :controls="false"
                                         placeholder="请输入年投资金额"
                                         @change="$refs.ruleForm.validateField('year_invest_num')"></el-input-number>
                        <div class="unit">万元</div>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="建设性质" prop="build_quality">
                        <!--<div class="radio_group">
                            <div :class="{radio:true,active:ruleForm.build_quality===item.value}" v-for="item in buildQuality" :key="item.value" @click="ruleForm.build_quality=item.value">
                                <div class="icon">
                                    <img class="default" src="../../assets/img/radio.png" alt="">
                                    <img class="active" src="../../assets/img/radio_active.png" alt="">
                                </div>
                                <div class="label">{{item.label}}</div>
                            </div>
                        </div>-->
                        <el-select size="medium" v-model="ruleForm.build_quality" popper-class="global_select_popper">
                            <el-option v-for="item in buildQuality" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="项目性质" prop="project_quality">
                        <el-select size="medium" v-model="ruleForm.project_quality" popper-class="global_select_popper">
                            <el-option v-for="item in projectQuality" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="项目类别" prop="project_genre">
                        <el-select size="medium" v-model="ruleForm.project_genre" popper-class="global_select_popper">
                            <el-option v-for="item in projectGenre" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="item long">
                    <el-form-item label="项目概况" prop="project_profile">
                        <el-input type="textarea" :rows="4" v-model="ruleForm.project_profile"
                                  placeholder="请输入项目概况"></el-input>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="联络小组" prop="contact_group">
                        <span>{{ ruleForm.contact_group.name || '' }}</span>
                        <el-button type="text" @click="chooseDepartment" style="margin-left: 5px;">选择部门</el-button>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="联络小组负责人" prop="contact_group_fuze">
                        <span>{{ ruleForm.contact_group_fuze.nickname || '' }}</span>
                        <el-button type="text" @click="choosePeople" style="margin-left: 5px;">选择联络小组负责人</el-button>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="项目负责单位" prop="project_unit">
                        <el-input v-model="ruleForm.project_unit" placeholder="请输入项目负责单位名称"></el-input>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="项目负责人" prop="project_fuze">
                        <el-input v-model="ruleForm.project_fuze" placeholder="请输入项目负责人名称"></el-input>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="负责人电话" prop="fuze_phone">
                        <el-input v-model="ruleForm.fuze_phone" placeholder="请输入项目负责人电话"></el-input>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="项目分管领导">
                        <el-input v-model="ruleForm.fgld" placeholder="请输入项目项目分管领导"></el-input>
                    </el-form-item>
                </div>
                <div class="item">
                    <el-form-item label="分管领导电话" prop="fgld_phone">
                        <el-input v-model="ruleForm.fgld_phone" placeholder="请输入项目分管领导电话"></el-input>
                    </el-form-item>
                </div>
                <!--<div class="item long">
                    <el-form-item label="项目变更情况" prop="is_change">
                        <div class="radio_group">
                            <div :class="{radio:true,active:ruleForm.is_change===item.value}" v-for="item in isChange" :key="item.value" @click="ruleForm.is_change=item.value">
                                <div class="icon">
                                    <img class="default" src="../../assets/img/radio.png" alt="">
                                    <img class="active" src="../../assets/img/radio_active.png" alt="">
                                </div>
                                <div class="label">{{item.label}}</div>
                            </div>
                        </div>
                    </el-form-item>
                </div>
                <div class="item long file_box change_files" v-if="ruleForm.is_change===2">
                    <el-form-item label="项目变更附件" prop="change_files">
                        <fileListComp :file_list="ruleForm.change_files" @change="ruleForm.change_files = $event" />
                        <div><el-button type="text" @click="updateFile">添加附件</el-button></div>
                    </el-form-item>
                </div>-->
                <div class="item long child">
                    <span>子项目</span>
                    <addChildProject :years="years" :parent_form="ruleForm"></addChildProject>
                </div>
                <div class="item long table" v-if="ruleForm.child_project_info.length>0">
                    <el-table :data="ruleForm.child_project_info">
                        <el-table-column type="index" label="序号" align="center"></el-table-column>
                        <el-table-column property="child_name" label="标题" align="center"></el-table-column>
                        <el-table-column label="投资金额" align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.child_all_invest_num }}万元</span>
                            </template>
                        </el-table-column>
                        <el-table-column v-for="(year,index) in years" :label="year+'年投资金额'" :key="index"
                                         align="center">
                            <template slot-scope="scope">
                                <span>{{ scope.row.child_year_invest_num[year] }}万元</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <div class="operation">
                                    <editChildProject :years="years" :parent_form="ruleForm" :child_form="scope.row"
                                                      :index="scope.$index"></editChildProject>
                                    <el-button type="text" class="del"
                                               @click="ruleForm.child_project_info.splice(scope.$index,1)">删除
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="button">
                <el-button class="button_88px" size="small" type="primary" @click="submitForm">保存</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import addChildProject from '@c/project/child_project/add_child_project.vue';
import count from '@/utils/count.js';
import peoplePicker from '@/utils/people_picker';
import editChildProject from '@c/project/child_project/edit_child_project.vue';
import dingDisk from '@/utils/ding_disk';
import fileListComp from '@/components/file/index.vue'

export default {
    components: {addChildProject, editChildProject, fileListComp},
    props: ['detail', 'prop_years', 'enter'],//enter:1表示从市项目管理点进来,enter:2表示从统计分析列表中点进来
    data() {
        // const check_all_invest_num = (rule, value, callback) => {
        //     if(value===0){
        //         callback(new Error('总投资金额不能为0'));
        //     }else{
        //         callback();
        //     }
        // };
        const check_year_invest_num = (rule, value, callback) => {
            if (value) {
                let arr = [];
                this.years.forEach(val => {
                    if (this.ruleForm.year_invest_num[val] !== undefined && this.ruleForm.year_invest_num[val] !== null && this.ruleForm.year_invest_num[val] !== '') {
                        arr.push(this.ruleForm.year_invest_num[val]);
                    }
                });
                if (arr.length === 0) {
                    callback(new Error('请输入年投资金额'));
                }
                if (arr.length !== this.years.length) {
                    callback(new Error('请输入每年投资金额'));
                }
                callback();
            } else {
                callback();
            }
        };
        const check_phone_number = (rule, value, callback) => {
            if (value && !(/^(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(value))) {
                callback(new Error('手机号码有误，请重填'));
            } else {
                callback();
            }
        };
        return {
            edit: false,//是否为编辑项目
            areaList: [
                {value:1, label:'州本级'},
                {value:2, label:'玛沁县'},
                {value:5, label:'甘德县'},
                {value:6, label:'达日县'},
                {value:4, label:'班玛县'},
                {value:3, label:'久治县'},
                {value:7, label:'玛多县'},
            ],
            buildQuality: [
                {value: 1, label: '新建'},
                {value: 2, label: '续建'},
                {value: 3, label: '其它'},
                {value: 4, label: '完工未竣工'},
                {value: 5, label: '跨年'},
            ],
            projectQuality: [
                {value: 1, label: '固定资产投资类'},
                {value: 2, label: '非固定资产投资类'},
                {value: 3, label: '其他'}
            ],
            projectGenre: [
                {value: 1, label: '保障和改善民生'},
                {value: 2, label: '文化教育支援'},
                {value: 3, label: '产业支援促进就业'},
                {value: 4, label: '智力支援'},
                {value: 5, label: '交往交流交融'},
                // {value:6,label:'基层组织和政权建设'}
            ],
            isChange: [
                {value: 1, label: '未变更'},
                {value: 2, label: '已变更'}
            ],
            ruleForm: JSON.parse(JSON.stringify(this.detail)),
            rules: {
                project_name: [{required: true, message: '请输入项目名称', trigger: 'blur'}],
                area: [{required: true, message: '请选择项目所属区域', trigger: 'change'}],
                address: [{required: true, message: '请输入项目所在地', trigger: 'blur'}],
                all_invest_num: [
                    {required: true, message: '请输入总投资金额', trigger: 'blur'},
                    // {validator:check_all_invest_num, trigger: 'blur'}
                ],
                // real_num:[{ required: true, message: '请输入实际投资金额', trigger: 'blur' },],
                time: [{required: true, message: '请选择建设周期', trigger: 'change'}],
                year_invest_num: [
                    {required: true, message: '请输入年投资金额', trigger: 'blur'},
                    {validator: check_year_invest_num, trigger: 'blur'}
                ],
                project_quality: [{required: true, message: '请选择项目性质', trigger: 'change'}],
                project_genre: [{required: true, message: '请选择项目类别', trigger: 'change'}],
                project_profile: [{required: true, message: '请输入项目概况', trigger: 'blur'}],
                // contact_group: [{required: true, message: '请选择联络小组部门', trigger: 'change'}],
                // contact_group_fuze: [{required: true, message: '请选择联络小组负责人', trigger: 'change'},],
                // project_unit: [{required: true, message: '请输入项目负责单位名称', trigger: 'blur'}],
                // project_fuze: [{required: true, message: '请输入项目负责人名称', trigger: 'blur'}],
                // fuze_phone: [
                //     {required: true, message: '请输入项目负责人电话', trigger: 'blur'},
                //     {validator: check_phone_number, trigger: 'blur'}
                // ],
                fgld_phone: [{validator: check_phone_number, trigger: 'blur'}]
            },
            years: JSON.parse(JSON.stringify(this.prop_years)),//用于获取当前选择建设周期的年份跨度
            project_detail: {},//编辑时传来的项目信息
        };
    },
    computed: {
        shi_project_project_power() {
            return this.$store.state.user.current_user.shi_project_project_power;
        },
        statistics_power() {
            return this.$store.state.user.current_user.statistics_power;
        }
    },
    methods: {
        //建设周期选择
        timeChange(value) {
            if (value) {
                this.years = [];
                let start = value[0].slice(0, 4);
                let end = value[1].slice(0, 4);
                for (let i = start; i <= end; i++) {
                    this.years.push(i);
                }
            }
        },
        //提交项目项目初始化表单 附加条件验证
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    const h = this.$createElement;
                    let message = [];//弹出的提示内容
                    if (this.ruleForm.child_project_info.length > 0) {
                        //子项目的年度投资金额是否全部填完
                        let check = true;
                        for (let child of this.ruleForm.child_project_info) {
                            if (child.child_all_invest_num === undefined || child.child_all_invest_num === null || child.child_all_invest_num === '') {
                                this.$message({message: '子项目有未填的投资金额', type: 'warning'});
                                check = false;
                                // return;
                            }
                            this.years.forEach(year => {
                                if (child.child_year_invest_num[year] === undefined || child.child_year_invest_num[year] === null || child.child_year_invest_num[year] === '') {
                                    this.$message({message: '子项目有未填的年度投资金额', type: 'warning'});
                                    check = false;
                                    // return;
                                } else {
                                    child[year] = child.child_year_invest_num[year];
                                }
                            });
                        }
                        if (!check) {
                            return;
                        }
                    }

                    //验证有误后弹出以下确认信息
                    if (message.length > 0) {
                        this.$msgbox({
                            title: '项目信息待确认',
                            message: h('p', null, message),
                            showCancelButton: true,
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            beforeClose: (action, instance, done) => {
                                if (action === 'confirm') {
                                    this.editProject();
                                    done();
                                } else {
                                    done();
                                }
                            }
                        }).catch(() => {

                        });
                    } else {
                        this.editProject();
                    }
                }
            });
        },
        editProject() {
            let param = {
                project_id: this.ruleForm.project_id,
                project_name: this.ruleForm.project_name,
                area: this.ruleForm.area,
                address: this.ruleForm.address,
                all_invest_num: this.ruleForm.all_invest_num * 10000,
                // real_num:this.ruleForm.real_num*10000,
                year_invest_num: JSON.parse(JSON.stringify(this.ruleForm.year_invest_num)),
                start_time: this.ruleForm.time[0],
                end_time: this.ruleForm.time[1],
                build_quality: this.ruleForm.build_quality,
                project_quality: this.ruleForm.project_quality,
                project_genre: this.ruleForm.project_genre,
                project_profile: this.ruleForm.project_profile,
                contact_group: this.ruleForm.contact_group.department_id,
                contact_group_fuze: this.ruleForm.contact_group_fuze.userid,
                project_unit: this.ruleForm.project_unit,
                project_fuze: this.ruleForm.project_fuze,
                fuze_phone: this.ruleForm.fuze_phone,
                is_change: this.ruleForm.is_change,
                change_files: JSON.stringify(this.ruleForm.change_files),
                fgld: this.ruleForm.fgld || '',
                fgld_phone: this.ruleForm.fgld_phone || '',
            };
            if (this.ruleForm.real_num === '' || this.ruleForm.real_num === undefined || this.ruleForm.real_num === null) {
                param.real_num = param.all_invest_num;
            } else {
                param.real_num = this.ruleForm.real_num * 10000;
            }
            // if(this.ruleForm.project_brfore_num!==undefined&&this.ruleForm.project_brfore_num!==''&&this.ruleForm.project_brfore_num!==null){
            //     param.project_brfore_num=this.ruleForm.project_brfore_num*10000
            // }
            for (let year in param.year_invest_num) {
                param.year_invest_num[year] = Number(param.year_invest_num[year]) * 10000;
            }
            param.year_invest_num = JSON.stringify(param.year_invest_num);
            param.child_project_info = [];
            let child_project_info = JSON.parse(JSON.stringify(this.ruleForm.child_project_info));
            child_project_info.forEach(item => {
                item.child_all_invest_num = Number(item.child_all_invest_num) * 10000;
                for (let year in item.child_year_invest_num) {
                    item.child_year_invest_num[year] = Number(item.child_year_invest_num[year]) * 10000;
                }
                param.child_project_info.push({
                    child_name: item.child_name,
                    child_all_invest_num: item.child_all_invest_num,
                    child_year_invest_num: item.child_year_invest_num
                });
            });
            param.child_project_info = JSON.stringify(param.child_project_info);
            api.project.addProject(param).then(res => {
                if (res.data.state == 1) {
                    this.$message({
                        message: '项目编辑成功',
                        type: 'success'
                    });
                    //项目概况
                    if (this.ruleForm.project_profile) {
                        let regN = /\n/g;
                        let regR = /\r/g;
                        this.ruleForm.new_project_profile = this.ruleForm.project_profile.replace(regN, "<br />").replace(regR, "<br />");
                    }
                    this.$emit('change', this.years, this.ruleForm);
                    this.$store.commit('user/SETEDITPROJECTINFO', {
                        ...param,
                        id: res.id
                    });
                }
            });
        },
        //选择联络小组
        chooseDepartment() {
            peoplePicker.departmentPicker([], (res) => {
                let info = {};
                info.department_id = res.departments[0].id;
                info.name = res.departments[0].name;
                this.ruleForm.contact_group = info;
            });
        },
        //选择联络小组负责人
        choosePeople() {
            peoplePicker.peoplePicker([], (res) => {
                let info = {};
                info.avatar = res.users[0].avatar;
                info.nickname = res.users[0].name;
                info.userid = res.users[0].emplId;
                this.ruleForm.contact_group_fuze = info;
            });
        },
        //上传附件
        updateFile() {
            dingDisk.dingDiskUpdate(res => {
                this.ruleForm.change_files = [...this.ruleForm.change_files, ...res.data];
            }, 3);
        },
    }
};
</script>

<style scoped lang="less">
.add_project {
    height: 100%;

    form {
        height: 100%;

        .item.change_files {
            .file:first-of-type {
                margin-top: 10px;
            }
        }
    }

    .row {
        .child {
            padding-left: 72px;
        }

        .table {
            padding-left: 30px;
        }
    }

    .back {
        position: absolute;
        width: 20px;
        height: 40px;
        left: 30px;
        top: 0;
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
            &.default {
                display: inline-block;
            }

            &.hover {
                display: none
            }
        }

        &:hover {
            img {
                &.default {
                    display: none
                }

                &.hover {
                    display: inline-block;
                }
            }
        }
    }

    .operation {
        width: 100% !important;

        .del {
            margin-left: 10px;
        }
    }
}
</style>