<template>
    <div class="check_item_detail">
        <el-popover placement="left-end" width="348" trigger="click" :visible-arrow="false" popper-class="child_popover check_item_detail" :value="show">
            <div class="content">
                <div class="header">
                    <h4>日常检查情况详情</h4>
                </div>
                <div class="detail">
                    <div class="item">
                        <div class="left">检查人</div>
                        <div class="right" v-if="detail.user_info">{{detail.user_info.nickname||''}}</div>
                    </div>
                    <div class="item">
                        <div class="left">检查时间</div>
                        <div class="right" v-if="detail.create_time">{{detail.create_time.slice(0,-3)}}</div>
                    </div>
                    <div class="item">
                        <div class="left">检查地址</div>
                        <div class="right" v-if="detail.address">{{detail.address.address||''}}</div>
                    </div>
                    <div class="item">
                        <div class="left">检查情况描述</div>
                        <div class="right" v-html="detail.new_check"></div>
                    </div>
                    <div class="item">
                        <div class="left">主要问题</div>
                        <div class="right" v-html="detail.new_question"></div>
                    </div>
                    <div class="item">
                        <div class="left">照片</div>
                        <div class="right file_box">
                            <div class="file" v-for="(item,index) in detail.file_ids" :key="index">
                                <div class="text" @click="previewFile(item)">{{item.fileName}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-button slot="reference" type="text" @click.native="show=false">详情</el-button>
        </el-popover>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    import dingDisk from '@/utils/ding_disk';
    import myPublic from '@/utils/public';
    export default {
        props:['id'],
        data(){
            return{
                show:false,detail:{}
            }
        },
        methods:{
            checkItemDetail(){
                api.project.checkItemDetail({id:this.id}).then(res=>{
                    if(res.data.state==1){
                        this.detail=res.data.rows||{};
                        if(myPublic.isJsonString(this.detail.file_ids)){
                            this.detail.file_ids=JSON.parse(this.detail.file_ids)
                        }else{
                            this.detail.file_ids=[]
                        }
                        if(myPublic.isJsonString(this.detail.address)){
                            this.detail.address=JSON.parse(this.detail.address)
                        }else{
                            this.detail.file_ids={}
                        }
                        let regN = /\n/g;
                        let regR = /\r/g;
                        //项目概况
                        if(this.detail.check){
                            this.detail.new_check=this.detail.check.replace(regN, "<br />").replace(regR, "<br />");
                        }
                        if(this.detail.question){
                            this.detail.new_question=this.detail.question.replace(regN, "<br />").replace(regR, "<br />");
                        }
                    }
                })
            },
            //预览附件
            previewFile(file_info){
                dingDisk.previewFile(file_info)
            }
        },
        watch:{
            show:{
                handler:function(val){
                    if(!val){
                        this.checkItemDetail();
                    }
                },
                immediate:true
            }
        }
    }
</script>

<style lang="less">
    .check_item_detail{
        /*.detail{*/
            /*max-height:450px!important;*/
            /*overflow: overlay;*/
        /*}*/
        .detail .item .left{
            padding:18px 15px 18px 20px!important;
            background:red;
        }
    }
</style>