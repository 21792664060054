<template>
    <div class="add_payment">
        <el-popover placement="right" width="348" trigger="manual" :visible-arrow="false" v-model="show"
                    @after-leave="show=false" popper-class="child_popover add_payment_popover">
            <div class="content">
                <div class="header">
                    <div class="icon" @click="show=false">
                        <img class="default" src="../../../assets/img/del.png" alt="">
                        <img class="hover" src="../../../assets/img/del_hover.png" alt="">
                    </div>
                    <h4>添加拨付资金</h4>
                </div>
                <el-form :model="ruleFormChild" :rules="rulesChild" ref="ruleFormChild" label-width="88px"
                         class="child_form add_payment_form">
                    <div class="label_left">
                        <el-form-item label="拨付类型" prop="type">
                            <el-select size="small" v-model="ruleFormChild.type" popper-class="global_select_popper">
                                <el-option v-for="item in type" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="拨付金额" prop="app_money">
                            <el-input-number v-model="ruleFormChild.app_money" size="small" :step="1" :controls="false"
                                             placeholder="请输入拨付金额" v-number-input.float></el-input-number>
                            <div class="unit">万元</div>
                        </el-form-item>
                        <el-form-item label="拨付对象" prop="app_object">
                            <el-input v-model="ruleFormChild.app_object" size="small" placeholder="请填写拨付对象"></el-input>
                        </el-form-item>
                        <el-form-item label="拨付日期" prop="app_data">
                            <el-date-picker size="small" v-model="ruleFormChild.app_data" type="date"
                                            placeholder="请选择拨付日期" value-format="yyyy-MM-dd"
                                            :clearable="false"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="备注" prop="remark">
                            <el-input v-model="ruleFormChild.remark" size="small" placeholder="请填写备注"></el-input>
                        </el-form-item>
                        <el-form-item class="file_box" label="附件" prop="app_file_ids">
                            <fileListComp :file_list="ruleFormChild.app_file_ids" @change="editNameBack" />
                            <div>
                                <el-button type="text" @click="updateFile">添加附件</el-button>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="button">
                        <el-button class="button_78px" size="mini" type="primary" @click="submitForm">保存</el-button>
                    </div>
                </el-form>
            </div>
            <el-button slot="reference" type="text" @click="show = true">添加项目拨付款</el-button>
        </el-popover>
    </div>
</template>

<script>
import dingDisk from '@/utils/ding_disk';
import fileListComp from '@/components/file/index.vue'

export default {
    components: {fileListComp},
    props: {
        parent_form: {
            type: Object,
            require: true
        },
        mark: {
            type: Boolean
        },
        all_invest_num: {}
    },
    data() {
        return {
            show: false,
            type: [
                {
                    value: 1,
                    label: '工程款'
                },
                {
                    value: 2,
                    label: '质保金'
                },
                {
                    value: 3,
                    label: '二类费用'
                },
                {
                    value: 4,
                    label: '其他'
                }
            ],
            ruleFormChild: {
                type: '',
                app_money: undefined,
                app_object: '',
                app_data: '',
                remark: '',
                app_file_ids: []
            },
            rulesChild: {
                type: [{required: true, message: '请选择拨付类型', trigger: 'change'}],
                app_money: [{required: true, message: '请输入拨付金额', trigger: 'blur'}],
                app_object: [{required: true, message: '请填写拨付对象', trigger: 'blur'}],
                app_data: [{required: true, message: '请选择拨付日期', trigger: 'change'}],
            }
        };
    },
    methods: {
        submitForm() {
            this.$refs.ruleFormChild.validate((valid) => {
                if (valid) {
                    //项目拨付资金返回母组件方法
                    let appBack = () => {
                        this.$emit('addApp',JSON.parse(JSON.stringify(this.ruleFormChild)))
                        let payment = this.parent_form.payment || [];
                        payment.splice(0, 0, JSON.parse(JSON.stringify(this.ruleFormChild)));
                        this.show = false;
                    };
                    if (this.all_invest_num && this.ruleFormChild.app_money > this.all_invest_num) {
                        this.$message.error(`您录入的拨付金额已超出项目总投资金额${this.all_invest_num}万元！`);
                        return;
                    }
                    if (this.ruleFormChild.app_money > 1000) {
                        const h = this.$createElement;
                        this.$msgbox({
                            title: '提示',
                            message: h('p', null, `您录入的金额过大，请确认录入金额是否为 ${this.ruleFormChild.app_money} 万元`),
                            showCancelButton: true,
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            beforeClose: (action, instance, done) => {
                                if (action === 'confirm') {
                                    appBack();
                                    done();
                                } else {
                                    done();
                                }
                            }
                        }).catch(() => {

                        });
                    } else {
                        appBack();
                    }
                }
            });
        },
        //上传附件
        updateFile() {
            dingDisk.dingDiskUpdate(res => {
                this.ruleFormChild.app_file_ids = [...this.ruleFormChild.app_file_ids, ...res.data];
            });
        },
        //预览附件
        previewFile(file_info) {
            dingDisk.previewFile(file_info);
        },
        //修改名称后的附件数组
        editNameBack(file_list) {
            this.ruleFormChild.app_file_ids = file_list;
        },
    },
    watch: {
        show: {
            handler: function (val) {
                if (val) {
                    this.ruleFormChild = {
                        type: '',
                        app_money: undefined,
                        app_object: '',
                        app_data: '',
                        remark: '',
                        app_file_ids: []
                    };
                    this.$refs.ruleFormChild.resetFields();
                }
            },
            immediate: true
        },
        mark: {
            handler: function (val) {
                this.show = !!val;
            },
            immediate: true
        }
    }
};
</script>

<style lang="less">
.add_payment_popover {
    .file_box {
        /*.el-form-item__content{
            max-height:80px;
            overflow-y: overlay;
        }*/

        .file {
            flex: 0 0 40px;
        }
    }
}
</style>