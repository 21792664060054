<template>
    <div class="payment_detail">
        <el-popover placement="left-end" width="348" trigger="click" :visible-arrow="false" popper-class="child_popover payment_detail_popover" :value="show">
            <div class="content">
                <div class="header">
                    <h4>拨付资金详情</h4>
                </div>
                <div class="detail">
                    <div class="item">
                        <div class="left">拨付类型</div>
                        <div class="right">{{detail.type}}</div>
                    </div>
                    <div class="item">
                        <div class="left">拨付金额</div>
                        <div class="right">{{detail.app_money}}万元</div>
                    </div>
                    <div class="item">
                        <div class="left">拨付对象</div>
                        <div class="right">{{detail.app_object}}</div>
                    </div>
                    <div class="item">
                        <div class="left">拨付日期</div>
                        <div class="right">{{detail.app_data}}</div>
                    </div>
                    <div class="item">
                        <div class="left">备注</div>
                        <div class="right">{{detail.remark}}</div>
                    </div>
                    <div class="item">
                        <div class="left">附件</div>
                        <div class="right file_box">
                            <div class="file" v-for="(item,index) in detail.app_file_ids" :key="index">
                                <div class="text" @click="previewFile(item)">{{item.fileName}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-button slot="reference" type="text" @click.native="show=false">详情</el-button>
        </el-popover>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    import computedData from '@/mixins/computedData.js';
    import dingDisk from '@/utils/ding_disk';
    export default {
        mixins:[computedData],
        props:['app_id'],
        data(){
            return{
                show:false, detail:{},
            }
        },
        methods:{
            appDetail(){
                this.detail={};
                api.project.appDetail({app_id:this.app_id}).then(res=>{
                    if(res.data){
                        let detail=res.data.rows||{};
                        if(detail.type) detail.type=this.initPaymentType(detail.type);
                        if(detail.app_data) detail.app_data=detail.app_data.slice(0,10);
                        if(detail.app_money) detail.app_money=Number(detail.app_money)/10000;
                        if(detail.app_file_ids){
                            detail.app_file_ids=JSON.parse(detail.app_file_ids);
                        }else{
                            detail.app_file_ids=[];
                        }
                        this.detail=detail;
                    }
                })
            },
            //预览附件
            previewFile(file_info){
                dingDisk.previewFile(file_info)
            }
        },
        watch:{
            show:{
                handler:function(val){
                    if(!val){
                        this.appDetail();
                    }
                },
                immediate:true
            }
        }
    }
</script>


<style lang="less">

</style>