<template>
    <div class="add_month_form">
        <el-dialog title="添加项目月报" :visible.sync="show" width="900px" class="add_month_form_dialog" append-to-body
                   :close-on-click-modal="false" :close-on-press-escape="false" @close="mark=''" @closed="close">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="parent_form">
                <div class="row">
                    <div class="item long">
                        <el-form-item label="项目进展情况" prop="project_evolve">
                            <!--<el-input v-model="ruleForm.project_evolve" placeholder="请输入项目进展情况"></el-input>-->
                            <el-input type="textarea" :rows="4" v-model="ruleForm.project_evolve"
                                      placeholder="请输入项目进展情况"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="项目状态" prop="project_status">
                            <el-select size="medium" v-model="ruleForm.project_status"
                                       popper-class="global_select_popper">
                                <el-option v-for="item in projectStatus" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <!-- <div class="item">
                         <el-form-item label="建设周期" prop="time">
                             <el-date-picker v-model="ruleForm.time" type="monthrange" range-separator="-" start-placeholder="选择日期" end-placeholder="选择日期"
                                 value-format="yyyy-MM" :clearable="false"></el-date-picker>
                         </el-form-item>
                     </div>-->
                    <div class="item">
                        <el-form-item label="填报月度" prop="month">
                            <el-date-picker v-model="ruleForm.month" type="month" placeholder="选择月度"
                                            value-format="yyyy-MM" :clearable="false"
                                            :editable="false" :picker-options="pickerOptions"></el-date-picker>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="实施情况(前期)" prop="implementation_before">
                            <el-select v-model="ruleForm.implementation_before" multiple collapse-tags
                                       @remove-tag="removeTagBefore"
                                       placeholder="请选择项目实施情况(前期)" popper-class="global_select_popper">
                                <el-option v-for="item in implementationBefore" :key="item.value" :label="item.label"
                                           :value="item.value"
                                           @click.stop.native="selectTag(item.value,ruleForm.implementation_before.indexOf(item.value)!==-1,'before')"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="实施情况(进度)" prop="implementation_rate">
                            <el-select v-model="ruleForm.implementation_rate" placeholder="请选择项目实施情况(进度)"
                                       popper-class="global_select_popper">
                                <el-option v-for="item in implementationRate" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="实施情况(后期)" prop="implementation_after">
                            <el-select v-model="ruleForm.implementation_after" multiple collapse-tags
                                       @remove-tag="removeTagAfter"
                                       placeholder="请选择项目实施情况(后期)" popper-class="global_select_popper">
                                <el-option v-for="item in implementationAfter" :key="item.value" :label="item.label"
                                           :value="item.value"
                                           @click.stop.native="selectTag(item.value,ruleForm.implementation_after.indexOf(item.value)!==-1,'after')"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item class="year_finish">
                            <div class="confirm">
                                <div class="icon" style="margin-right: 25px;" :class="{active:ruleForm.year_finish===2}"
                                     @click="selectYearFinish">
                                    <img class="default" src="../../../assets/img/checkbox.png" alt="">
                                    <img class="active" src="../../../assets/img/checkbox_active.png" alt="">
                                </div>
                                <div class="text">本年度工作已完工</div>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="年度项目进展率" prop="year_project_evolve_rate">
                            <el-input-number v-model="ruleForm.year_project_evolve_rate" :precision="0" :step="1"
                                             :min="0" :max="100" :controls="false"
                                             placeholder="请输入项目年度进展率（%）"></el-input-number>
                            <div class="unit">%</div>
                        </el-form-item>
                    </div>
                    <div class="item long">
                        <el-form-item label="项目状况描述" prop="project_status_des">
                            <!--<el-input v-model="ruleForm.project_status_des" placeholder="请输入项目状况描述"></el-input>-->
                            <el-input type="textarea" :rows="4" v-model="ruleForm.project_status_des"
                                      placeholder="请输入项目状况描述"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item long file_box">
                        <el-form-item label="项目进展附件" prop="project_file_ids">
                            <fileListComp :file_list="ruleForm.project_file_ids" @change="editNameBack" />
                            <div>
                                <el-button type="text" @click="updateFile">添加附件</el-button>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item long"
                         v-if="(moneyInfo.money1&&moneyInfo.money1_status==1)||(moneyInfo.money2&&moneyInfo.money2_status==1)">
                        <el-form-item label="待确认资金" class="money_confirm">
                            <div class="confirm" v-if="moneyInfo.money1&&moneyInfo.money1_status==1">
                                <div class="text">第一次拨款{{ Number(moneyInfo.money1) / 10000 }}万元</div>
                                <div class="icon" :class="{active:confirm1}" @click="confirm1=!confirm1">
                                    <img class="default" src="../../../assets/img/checkbox.png" alt="">
                                    <img class="active" src="../../../assets/img/checkbox_active.png" alt="">
                                </div>
                            </div>
                            <div class="confirm" v-if="moneyInfo.money2&&moneyInfo.money2_status==1">
                                <div class="text">第二次拨款{{ Number(moneyInfo.money2) / 10000 }}万元</div>
                                <div class="icon" :class="{active:confirm2}" @click="confirm2=!confirm2">
                                    <img class="default" src="../../../assets/img/checkbox.png" alt="">
                                    <img class="active" src="../../../assets/img/checkbox_active.png" alt="">
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item long">
                        <el-form-item label="项目拨付资金" prop="project_file_ids">
                            <div>
                                <addPayment :parent_form="ruleForm" :mark="mark===-1"
                                            :all_invest_num="all_invest_num" @click.native="mark=-1"></addPayment>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item long table">
                        <el-table :data="ruleForm.payment">
                            <el-table-column type="index" label="序号" align="center"></el-table-column>
                            <el-table-column label="拨付类型" align="center">
                                <template slot-scope="scope">
                                    <span>{{ initPaymentType(scope.row.type) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="拨付金额" align="center">
                                <template slot-scope="scope">
                                    {{ scope.row.app_money }}万元
                                </template>
                            </el-table-column>
                            <el-table-column property="app_object" label="拨付对象" align="center"></el-table-column>
                            <el-table-column label="拨付日期" align="center">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.app_data }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <editPayment :parent_form="ruleForm" :child_form="scope.row" :index="scope.$index"
                                                 :mark="mark===scope.$index" :all_invest_num="all_invest_num"
                                                 @click.native="mark=scope.$index"></editPayment>
                                    <el-button type="text" style="margin-left: 10px;"
                                               @click="ruleForm.payment.splice(scope.$index,1)">删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="button">
                    <el-button class="button_88px" size="small" type="primary" @click="submitForm">保存</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import addPayment from '@c/project/payment/add_payment.vue';
import editPayment from '@c/project/payment/edit_payment.vue';
import computedData from '@/mixins/computedData';
import dingDisk from '@/utils/ding_disk';
import count from '@/utils/count';
import fileListComp from '@/components/file/index.vue'

export default {
    mixins: [computedData],
    components: {addPayment, editPayment, fileListComp},
    props: ['yearFinish', 'all_invest_num'],
    data() {
        return {
            project_detail: {},
            formList: [],//项目里的所有月报列表信息,用于循环以前的实施情况(前期)和实施情况(后期)选项
            default_implementation_before: [],
            default_implementation_after: [],//之前月报已经选过的实施情况选项
            _implementation_before: [],
            _implementation_after: [],//上一次的已选值,用于计算选择选项之前的index
            show: false,
            projectStatus: [
                {
                    value: 1,
                    label: '项目未开始实施'
                },
                {
                    value: 2,
                    label: '前期准备过程中'
                },
                {
                    value: 3,
                    label: '项目开始实施'
                },
                {
                    value: 4,
                    label: '项目进程过半'
                },
                {
                    value: 5,
                    label: '完工未验收'
                },
                {
                    value: 6,
                    label: '竣工验收'
                },
                {
                    value: 7,
                    label: '终止项目'
                },
                {
                    value: 8,
                    label: '续建项目'
                },
                // {
                //     value:9,
                //     label:'变更项目'
                // }
            ],
            implementationBefore: [
                {
                    value: 1,
                    label: '批复/下达'
                },
                {
                    value: 2,
                    label: '招投标/政府采购'
                },
                {
                    value: 3,
                    label: '开工'
                }
            ],
            implementationRate: [
                {
                    value: 5,
                    label: '0%'
                },
                {
                    value: 1,
                    label: '20%'
                },
                {
                    value: 2,
                    label: '50%'
                },
                {
                    value: 3,
                    label: '80%'
                },
                {
                    value: 4,
                    label: '100%'
                }
            ],
            implementationAfter: [
                {
                    value: 1,
                    label: '完工'
                },
                {
                    value: 2,
                    label: '验收'
                },
                {
                    value: 3,
                    label: '审计'
                }
            ],
            ruleForm: {
                project_evolve: '',
                project_status: '',
                // time:'',
                implementation_before: [],
                implementation_rate: '',
                implementation_after: [],
                year_project_evolve_rate: undefined,
                project_status_des: '',
                month: '',
                project_file_ids: [],
                payment: [],
                year_finish: 1,//本年度是否已完成 , 1:未完成, 2:已完成
            },
            rules: {
                project_status: [{required: true, message: '请选择项目状态', trigger: 'change'}],
                // time:[{ required: true, message: '请选择建设周期',trigger: 'change' }],
                implementation_rate: [{required: true, message: '请选择实施情况(进度)', trigger: 'change'}],
                project_evolve: [{required: true, message: '请输入项目进展情况', trigger: 'blur'}],
                year_project_evolve_rate: [{required: true, message: '请输入项目年度进展率', trigger: 'blur'}],
                project_status_des: [{required: true, message: '请输入项目状况描述', trigger: 'blur'}],
                month: [{required: true, message: '请选择填报月度', trigger: 'change'}],
            },
            form_id: '',
            moneyInfo: {},//确认资金相关信息
            confirm1: false, confirm2: false,//确认的第几次拨款
            mark: '',//项目拨付款识别用的,0,1,2等数字为编辑的项目拨付款index,-1为新建
            pickerOptions: {
                disabledDate(time) {
                    return new Date(`${count.com_current_month(25).year}-${count.com_current_month(25).month}-01 00:00:00`) >= time;
                }
            }
        };
    },
    methods: {
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if (this.ruleForm.year_project_evolve_rate === 100 && this.ruleForm.year_finish !== 2) {
                        const h = this.$createElement;
                        this.$msgbox({
                            title: '提示',
                            message: h('p', null, '本年度工作是否完工?'),
                            closeOnClickModal: false,
                            showClose: false,
                            showCancelButton: true,
                            confirmButtonText: '是',
                            cancelButtonText: '否',
                            beforeClose: (action, instance, done) => {
                                if (action === 'confirm') {
                                    this.ruleForm.year_finish = 2;
                                    this.addForm().then(res => {
                                        if (res.reject) {
                                            if (this.ruleForm.payment.length > 0) {
                                                this.addAppOneByOne();
                                            } else {
                                                this.$emit('refresh');
                                            }
                                        }
                                    });
                                    done();
                                } else {
                                    this.addForm().then(res => {
                                        if (res.reject) {
                                            if (this.ruleForm.payment.length > 0) {
                                                this.addAppOneByOne();
                                            } else {
                                                this.$emit('refresh');
                                            }
                                        }
                                    });
                                    done();
                                }
                            }
                        }).catch(() => {

                        });
                    } else {
                        this.addForm().then(res => {
                            if (res.reject) {
                                if (this.ruleForm.payment.length > 0) {
                                    this.addAppOneByOne();
                                } else {
                                    this.$emit('refresh');
                                }
                            }
                        });
                    }
                }
            });
        },
        addForm() {
            let param = JSON.parse(JSON.stringify(this.ruleForm));
            // delete param.time;
            delete param.payment;
            param.project_id = this.project_detail.project_id;
            // param.project_start_time=this.ruleForm.time[0];
            // param.project_end_time=this.ruleForm.time[1];
            param.implementation_before = this.ruleForm.implementation_before.join(',');
            param.implementation_after = this.ruleForm.implementation_after.join(',');
            param.project_file_ids = JSON.stringify(this.ruleForm.project_file_ids);
            param.year_finish = this.ruleForm.year_finish;
            return api.project.addForm(param).then(res => {
                if (res.data.state == 1) {
                    this.$message({
                        message: '添加项目月报成功',
                        type: 'success'
                    });
                    this.form_id = res.data.form_id;
                    this.show = false;
                    this.$emit('editYearFinish', this.ruleForm.year_finish);
                    if (this.confirm1) {
                        this.confirmFund(1);
                    }
                    if (this.confirm2) {
                        this.confirmFund(2);
                    }
                    return {reject: true};
                } else {
                    return {reject: false};
                }
            });
        },
        addApp(param, i) {
            return api.project.addApp({
                ...param,
                // type:param.type,
                // app_money:param.app_money,
                // app_object:param.app_object,
                // app_data:param.app_data,
                // remark:param.remark,
                // app_file_ids:JSON.stringify(param.app_file_ids),
                project_id: this.project_detail.project_id,
                // form_id: this.form_id
            }).then(res => {
                if (res.data.state == 1) {
                    if (i === this.ruleForm.payment.length - 1) {
                        this.$emit('refresh');
                    }
                    return {reject: true};
                } else {
                    return {reject: false};
                }
            });
        },
        async addAppOneByOne() {
            for (let i = 0; i < this.ruleForm.payment.length; i++) {
                let param = this.ruleForm.payment[i];
                param.app_file_ids = JSON.stringify(param.app_file_ids);
                param.app_money = Number(param.app_money) * 10000;
                await this.addApp(param, i);
            }
        },
        close() {
            this.ruleForm = {
                project_evolve: '',
                project_status: '',
                // time:'',
                implementation_before: [],
                implementation_rate: '',
                implementation_after: [],
                year_project_evolve_rate: undefined,
                project_status_des: '',
                month: '',
                project_file_ids: [],
                payment: [],
                year_finish: 1
            };
            this.confirm1 = false;
            this.confirm2 = false;
            this.$refs.ruleForm.resetFields();
        },
        //上传附件
        updateFile() {
            dingDisk.dingDiskUpdate(res => {
                this.ruleForm.project_file_ids = [...this.ruleForm.project_file_ids, ...res.data];
            }, 3);
        },
        //预览附件
        previewFile(file_info) {
            dingDisk.previewFile(file_info);
        },
        //修改名称后的附件数组
        editNameBack(file_list) {
            this.ruleForm.project_file_ids = file_list;
        },
        fundList() {
            api.money.fundLists({project_id: this.project_detail.project_id}).then(res => {
                if (res.data && res.data.lists) {
                    if (res.data.lists.length > 0) {
                        this.moneyInfo = res.data.lists[0];
                    }
                }
            });
        },
        //确认资金到账
        confirmFund(type) {
            api.money.confirmFund({id: this.moneyInfo.id, type: type, month: this.ruleForm.month}).then(res => {

            });
        },
        //循环月报列表筛选出以前所选的实施情况前期和后期情况,已选过的默认勾选并且不允许取消
        getImplementation() {
            let new_implementation_before = [];
            let new_implementation_after = [];
            this.formList.forEach(item => {
                new_implementation_before.push(...item.implementation_before);
                new_implementation_after.push(...item.implementation_after);
            });
            new_implementation_before = Array.from(new Set([...new_implementation_before]));
            new_implementation_after = Array.from(new Set([...new_implementation_after]));
            this.default_implementation_before = JSON.parse(JSON.stringify(new_implementation_before));
            this.default_implementation_after = JSON.parse(JSON.stringify(new_implementation_after));
            this.ruleForm.implementation_before = JSON.parse(JSON.stringify(new_implementation_before));
            this.ruleForm.implementation_after = JSON.parse(JSON.stringify(new_implementation_after));
            if (this.yearFinish === 1) {
                if (this.ruleForm.implementation_after.length > 0) {
                    this.ruleForm.year_finish = 2;
                } else {
                    this.ruleForm.year_finish = 1;
                }
            } else {
                this.ruleForm.year_finish = JSON.parse(JSON.stringify(this.yearFinish));
            }
        },
        /**
         * 选择tag选项,如果是取消选择并且之前的月报中有选过该选项的话,则重新添加进选项中
         * @param val 当前点击的选项值value
         * @param is_selected 是否是取消选择
         * @param type before或者after, 实施情况前期或后期
         */
        selectTag(val, is_selected, type) {
            if (!is_selected) {
                if (type === 'before' && this.default_implementation_before.indexOf(val) !== -1) {
                    this.ruleForm.implementation_before.splice(this._implementation_before.indexOf(val), 0, val);
                }
                if (type === 'after' && this.default_implementation_after.indexOf(val) !== -1) {
                    this.ruleForm.implementation_after.splice(this._implementation_after.indexOf(val), 0, val);
                }
            }
            if (type === 'after') {
                if (this.ruleForm.implementation_after.length > 0) {
                    this.ruleForm.year_finish = 2;
                } else {
                    this.ruleForm.year_finish = 1;
                }
            }
        },
        //实施情况(前期)多选项点击叉号删除tag
        removeTagBefore(val) {
            if (this.default_implementation_before.indexOf(val) !== -1) {
                this.ruleForm.implementation_before.unshift(val);
            }
        },
        //实施情况(后期)多选项点击叉号删除tag
        removeTagAfter(val) {
            if (this.default_implementation_after.indexOf(val) !== -1) {
                this.ruleForm.implementation_after.unshift(val);
            }
            if (this.ruleForm.implementation_after.length > 0) {
                this.ruleForm.year_finish = 2;
            } else {
                this.ruleForm.year_finish = 1;
            }
        },
        //选择或取消选择本年度工作已完工
        selectYearFinish() {
            if (this.ruleForm.implementation_after.length > 0) {
                this.$message({
                    message: '已选择实施情况(后期)，不能取消已完工!',
                    type: 'warning'
                });
            } else {
                this.ruleForm.year_finish === 2 ? this.ruleForm.year_finish = 1 : this.ruleForm.year_finish = 2;
            }
        }
    },
    watch: {
        show: {
            handler: function (val) {
                if (val) {
                    this.fundList();
                    this.getImplementation();
                }
            },
            immediate: true
        },
        'ruleForm.implementation_before': {
            handler: function (n, o) {
                if (o) {
                    this._implementation_before = JSON.parse(JSON.stringify(o));
                }
            },
            immediate: true
        },
        'ruleForm.implementation_after': {
            handler: function (n, o) {
                if (o) {
                    this._implementation_after = JSON.parse(JSON.stringify(o));
                }
            },
            immediate: true
        },
        'ruleForm.project_status': {
            handler: function (n, o) {
                if (o) {
                    if (this.ruleForm.project_status === 3 || this.ruleForm.project_status === 4 || this.ruleForm.project_status === 5 || this.ruleForm.project_status === 6) {
                        if (this.ruleForm.implementation_before.indexOf(3) === -1) {
                            this.ruleForm.implementation_before.push(3);
                        }
                    }
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="less">
.add_month_form_dialog {
    .table {
        padding-left: 30px;
    }

    .parent_form {
        .el-date-editor {
            width: 100%;
        }

        .el-form-item.money_confirm {
            .el-form-item__content {
                display: flex;
                flex-direction: column;

            }
        }

        .el-form-item.year_finish {
            .el-form-item__content {
                margin-left: 25px !important;
            }
        }

        .confirm {
            display: flex;
            align-items: center;

            .text {
                flex: 0 0 200px;
            }

            .icon {
                width: 16px;
                height: 16px;
                position: relative;
                cursor: pointer;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    transition: opacity 0.3s;

                    &.default {
                        opacity: 1;
                    }

                    &.active {
                        opacity: 0;
                    }
                }

                &.active {
                    img {
                        &.default {
                            opacity: 0;
                        }

                        &.active {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
</style>