<template>
    <div class="project_detail">
        <el-button size="small" type="primary" @click="open" class="button_78px">查看详情</el-button>
        <el-dialog
            :title="dialogType==='detail'?detail.project_name:dialogType==='edit'?'编辑项目':detail.project_name"
            :visible.sync="show" class="project_detail_dialog" append-to-body :close-on-click-modal="false"
            width="1105px" @closed="$emit('refresh',true);mark=''">
            <!--月报详情-->
            <monthFormDetail ref="monthFormDetail"></monthFormDetail>
            <div class="detail_box" v-if="dialogType==='detail'">
                <div class="detail">
                    <div class="content">
                        <div class="title">项目基础信息</div>
                        <div class="detail_item items">
                            <div class="item long">
                                <div class="left">项目名称</div>
                                <div class="right">{{ detail.project_name }}</div>
                            </div>
                            <div class="item">
                                <div class="left">项目所属区域</div>
                                <div class="right">{{ initArea(detail.area) }}</div>
                            </div>
                            <div class="item">
                                <div class="left">项目所在地</div>
                                <div class="right">{{ detail.address }}</div>
                            </div>
                            <div class="item">
                                <div class="left">总投资金额</div>
                                <div class="right">{{ detail.all_invest_num }}万元</div>
                            </div>
                            <div class="item">
                                <div class="left">实际投资金额</div>
                                <div class="right">{{ detail.real_num }}<span
                                    v-if="detail.real_num!==''&&detail.real_num!==undefined&&detail.real_num!==null">万元</span>
                                </div>
                            </div>
                            <div class="item">
                                <div class="left">建设周期</div>
                                <div class="right">
                                    <span
                                        v-if="detail.time&&detail.time.length>0">{{
                                            detail.time[0].slice(0, 4)
                                        }}年{{ detail.time[0].slice(5, 7) }}月-{{
                                            detail.time[1].slice(0, 4)
                                        }}年{{ detail.time[1].slice(5, 7) }}月</span>
                                </div>
                            </div>
                            <!--<div class="item">
                                <div class="left">所属年度</div>
                                <div class="right">{{new Date().getFullYear()}}年</div>
                            </div>-->
                            <div class="item" v-for="(year,index) in years" :key="index">
                                <div class="left">{{ year }}年投资金额</div>
                                <div class="right">{{ detail.year_invest_num[year] }}万元</div>
                            </div>
                            <div class="item">
                                <div class="left">建设性质</div>
                                <div class="right">{{ initBuildQuality(detail.build_quality) }}</div>
                            </div>
                            <div class="item">
                                <div class="left">项目性质</div>
                                <div class="right">{{ initProjectQuality(detail.project_quality) }}</div>
                            </div>
                            <div class="item">
                                <div class="left">项目类别</div>
                                <div class="right">{{ initProjectGenre(detail.project_genre) }}</div>
                            </div>
                            <div class="item">
                                <div class="left">本年度工作情况</div>
                                <div class="right">{{ detail.year_finish === 2 ? '已完工' : '未完工' }}</div>
                            </div>
                            <div class="item long">
                                <div class="left">项目概况</div>
                                <div class="right" v-html="detail.new_project_profile"></div>
                            </div>
                            <div class="item">
                                <div class="left">联络小组</div>
                                <div class="right">{{ detail.contact_group ? detail.contact_group.name : '' }}</div>
                            </div>
                            <div class="item">
                                <div class="left">联络小组负责人</div>
                                <div class="right">
                                    {{ detail.contact_group_fuze ? detail.contact_group_fuze.nickname : '' }}
                                </div>
                            </div>
                            <div class="item">
                                <div class="left">项目负责单位</div>
                                <div class="right">{{ detail.project_unit }}</div>
                            </div>
                            <div class="item">
                                <div class="left">项目负责人</div>
                                <div class="right">{{ detail.project_fuze }}</div>
                            </div>
                            <div class="item">
                                <div class="left">负责人电话</div>
                                <div class="right">{{ detail.fuze_phone }}</div>
                            </div>
                            <div class="item">
                                <div class="left">项目分管领导</div>
                                <div class="right">{{ detail.fgld }}</div>
                            </div>
                            <div class="item long">
                                <div class="left">分管领导电话</div>
                                <div class="right">{{ detail.fgld_phone }}</div>
                            </div>
                            <div class="item long" v-if="false">
                                <div class="left">项目审计情况</div>
                                <div class="right" v-html="detail.new_audit"></div>
                            </div>
                            <div class="item long">
                                <div class="left">项目检查问题</div>
                                <div class="right" v-html="detail.new_check"></div>
                            </div>
                            <div class="item long">
                                <div class="left">项目评估内容</div>
                                <div class="right" v-html="detail.new_assess"></div>
                            </div>
                        </div>
                        <div class="title">项目变更</div>
                        <div class="project_manage files">
                            <div class="files_items row">
                                <div class="item long">
                                    <div class="left">项目变更情况</div>
                                    <div class="right" v-if="!shi_project_project_power.power.change">
                                        {{ initIsChange(detail.is_change) }}
                                    </div>
                                    <div class="right" v-if="shi_project_project_power.power.change">
                                        <div class="radio_group">
                                            <div :class="{radio:true,active:detail.is_change===item.value}"
                                                 v-for="item in isChange" :key="item.value"
                                                 @click="chooseChange(item.value)">
                                                <div class="icon">
                                                    <img class="default" src="../../assets/img/radio.png" alt="">
                                                    <img class="active" src="../../assets/img/radio_active.png" alt="">
                                                </div>
                                                <div class="label">{{ item.label }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item long" v-if="detail.is_change==2">
                                    <div class="left">项目变更附件</div>
                                    <div class="right file_box">
                                        <fileListComp :file_list="detail.change_files" @change="editNameBackChange" :show_edit="shi_project_project_power.power.change" />
                                        <div
                                            v-if="!shi_project_project_power.power.change&&detail.change_files.length===0">
                                            无
                                        </div>
                                        <div v-if="shi_project_project_power.power.change">
                                            <el-button size="mini" type="text" style="font-size: 14px;"
                                                       @click="updateChangeFile">添加附件
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="item long" style="justify-content: center">
                                    <el-button size="mini" type="primary" @click="changeApi"
                                               v-if="shi_project_project_power.power.change">保存变更
                                    </el-button>
                                </div>
                            </div>
                        </div>
                        <div class="title">项目管理</div>
                        <div class="project_manage files">
                            <div class="files_items row">
                                <div class="item long">
                                    <div class="left">立项前期费用</div>
                                    <el-input-number size="mini" v-model="project_brfore_num" :step="1"
                                                     :disabled="!shi_project_project_power.power.upload"
                                                     :controls="false" placeholder="点击填写金额"
                                                     @blur="managementEdit"></el-input-number>
                                    万元
                                </div>
                                <div class="item" v-for="(item,index) in fileList" :key="'index'+index">
                                    <div class="left">{{ initFileList(item.value) }}</div>
                                    <div class="right file_box">
                                        <fileListComp :file_list="item.files" @change="editNameBackManage($event,item)" :show_edit="shi_project_project_power.power.upload" />
                                        <el-button size="mini" type="text" style="margin-left: 5px;font-size: 14px;"
                                                   v-if="shi_project_project_power.power.upload"
                                                   @click="updateFile(item)">添加附件
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="btn">
                                <el-button size="mini" type="primary" class="button_88px" @click="managementEdit"
                                    v-if="$store.state.user.power[Number($store.state.user.current_user.part)].operation.project_manage">保存</el-button>
                            </div>-->
                        </div>
                        <div class="detail_item table"
                             v-if="detail.child_project_info&&detail.child_project_info.length>0">
                            <div class="title">子项目</div>
                            <el-table :data="detail.child_project_info">
                                <el-table-column type="index" label="序号" align="center"></el-table-column>
                                <el-table-column label="标题" align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row.child_name }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="投资金额" align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row.child_all_invest_num }}万元
                                    </template>
                                </el-table-column>
                                <el-table-column v-for="(year,index) in years" :key="index" :label="year+'年投资金额'"
                                                 align="center">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.child_year_invest_num[year] }}万元</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="detail_item table">
                            <div class="title">拨付汇总表</div>
                            <el-table :data="payment">
                                <el-table-column type="index" label="序号" align="center"></el-table-column>
                                <el-table-column label="拨付类型" align="center">
                                    <template slot-scope="scope">
                                        <span>{{ initPaymentType(scope.row.type) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="拨付金额" align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row.app_money }}万元
                                    </template>
                                </el-table-column>
                                <el-table-column property="app_object" label="拨付对象" align="center"></el-table-column>
                                <el-table-column label="拨付日期" align="center">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.app_data.slice(0, 10) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center">
                                    <template slot-scope="scope">
                                        <editPayment style="margin-right: 10px"
                                                     v-if="shi_project_project_power.power.month_form"
                                                     :parent_form="{}" :child_form="scope.row"
                                                     :mark="mark===scope.$index" :all_invest_num="detail.all_invest_num"
                                                     @click.native="mark=scope.$index" @addApp="addApp"></editPayment>
                                        <paymentDetail
                                            :app_id="scope.row.app_id"></paymentDetail>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <p class="money_total">
                                <addPayment v-if="shi_project_project_power.power.month_form"
                                            :parent_form="{}" :all_invest_num="detail.all_invest_num"
                                            :mark="mark===-1" @click.native="mark=-1"
                                            @addApp="addApp"></addPayment>
                                本项目已经拨付资付总金额：{{ money_total }}万元，占比：{{ money_rate }}%
                            </p>
                        </div>
                        <div class="detail_item table">
                            <div class="title">项目进展附件汇总表</div>
                            <div class="table_box">
                                <el-table :data="monthFiles">
                                    <el-table-column type="index" label="序号" align="center"
                                                     width="200"></el-table-column>
                                    <el-table-column label="附件名称" align="center">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.fileName }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" align="center" width="185">
                                        <template slot-scope="scope">
                                            <el-button type="text" @click="previewFile(scope.row)">预览附件</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div class="detail_item table month_form">
                            <!--<div class="add_month_form">
                                <el-button size="small" type="primary" @click="addMonthForm" class="button_98px">新增月报</el-button>
                            </div>-->
                            <div class="title">项目月报</div>
                            <div class="table_box">
                                <el-table :data="formList">
                                    <el-table-column type="index" label="序号" align="center"
                                                     width="200"></el-table-column>
                                    <el-table-column label="填报月份" align="center">
                                        <template slot-scope="scope">
                                            <span
                                                v-if="scope.row.month">{{
                                                    scope.row.month.slice(0, 4)
                                                }}年{{ scope.row.month.slice(5, 7) }}月</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" align="center" width="185">
                                        <template slot-scope="scope">
                                            <editMonthForm :form_id="scope.row.form_id" :project_id="detail.project_id"
                                                           :formList="formList"
                                                           :form_month="`${scope.row.month.slice(0,4)}-${scope.row.month.slice(5,7)}`"
                                                           :yearFinish="detail.year_finish"
                                                           :all_invest_num="detail.all_invest_num"
                                                           @refresh="refresh"
                                                           @editYearFinish="editYearFinish"
                                                           v-if="(enter==1&&shi_project_project_power.power.month_form)||(enter==2&&statistics_power.power.edit)"></editMonthForm>
                                            <el-button type="text" @click="toMonthFormDetail(scope.row.form_id)">详情
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div class="detail_item table" v-if="checkItems.length>0">
                            <div class="title">援青干部项目检查日志</div>
                            <div class="table_box">
                                <el-table :data="checkItems">
                                    <el-table-column type="index" label="序号" align="center"></el-table-column>
                                    <el-table-column label="地址" align="center">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.address_obj.address }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column property="show_name" label="检查人" align="center"></el-table-column>
                                    <el-table-column property="show_time" label="检查时间" align="center"></el-table-column>
                                    <el-table-column label="操作" align="center">
                                        <template slot-scope="scope">
                                            <el-button type="text" style="margin-right: 10px;"
                                                       @click="delCheck(scope.row.id)"
                                                       v-if="$store.state.user.current_user.userid===scope.row.user_info.userid">
                                                删除
                                            </el-button>
                                            <checkItemDetail :id="scope.row.id"></checkItemDetail>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div class="detail_item table" v-if="checkItems2.length>0">
                            <div class="title">第三方项目检查日志</div>
                            <div class="table_box">
                                <el-table :data="checkItems2">
                                    <el-table-column type="index" label="序号" align="center"></el-table-column>
                                    <el-table-column label="地址" align="center">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.address_obj.address }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column property="show_name" label="检查人" align="center"></el-table-column>
                                    <el-table-column property="show_time" label="检查时间" align="center"></el-table-column>
                                    <el-table-column label="操作" align="center">
                                        <template slot-scope="scope">
                                            <el-button type="text" style="margin-right: 10px;"
                                                       @click="delCheck(scope.row.id)"
                                                       v-if="$store.state.user.current_user.userid===scope.row.user_info.userid">
                                                删除
                                            </el-button>
                                            <checkItemDetail :id="scope.row.id"></checkItemDetail>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div class="detail_item report_list table" v-if="reportList.length>0">
                            <div class="title">技术报告列表</div>
                            <div class="table_box">
                                <el-table :data="reportList">
                                    <el-table-column type="index" label="序号" align="center"
                                                     width="200px"></el-table-column>
                                    <el-table-column label="技术报告名称" align="center">
                                        <template slot-scope="scope">
                                            <div class="files">
                                                <div class="file" style="" v-for="(v,k) in scope.row.file_ids"
                                                     :key="k+1" @click="previewFile(v)">{{ v.fileName }}
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="上传月度" align="center" width="200px">
                                        <template slot-scope="scope">
                                            <span>{{ scope.row.month }}</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                    <div class="button" v-if="
                            (enter==1&&shi_project_project_power.power.month_form)
                            ||
                            (enter==1&&shi_project_project_power.power.edit)
                            ||
                            (enter==1&&shi_project_project_power.power.stop)
                            ||
                            (enter==1&&shi_project_project_power.power.delete)
                            ||
                            (enter==2&&statistics_power.power.edit)
                        ">
                        <el-button size="small" type="primary" @click="addMonthForm" class="button_98px"
                                   v-if="(enter==1&&shi_project_project_power.power.month_form)||(enter==2&&statistics_power.power.edit)">
                            新增月报
                        </el-button>
                        <el-button size="small" type="primary" @click="dialogType='edit'" class="button_98px"
                                   v-if="((enter==1&&shi_project_project_power.power.edit)||(enter==2&&statistics_power.power.edit))&&$store.state.user.project_edit_data.project_edit==1">
                            编辑项目
                        </el-button>
                        <el-button size="small" type="primary" @click="stopProject(detail.project_id)"
                                   class="button_98px" :disabled="detail.project_status==7"
                                   v-if="(enter==1&&shi_project_project_power.power.stop)||(enter==2&&statistics_power.power.edit)">
                            终止项目
                        </el-button>
                        <el-button size="small" type="primary" @click="deleteProject(detail.project_id)"
                                   class="button_98px"
                                   v-if="(enter==1&&shi_project_project_power.power.delete)||(enter==2&&statistics_power.power.edit)">
                            删除项目
                        </el-button>
                        <!--<el-button v-if="years.length===1&&$store.state.user.power[Number($store.state.user.current_user.part)].operation.finish_project"
                            size="small" type="primary" @click="yearFinish(years[0])" class="button_98px" :disabled="finish_info.indexOf(years[0])!==-1">完成项目</el-button>
                        <el-dropdown trigger="click" v-if="years.length>1&&$store.state.user.power[Number($store.state.user.current_user.part)].operation.finish_project"
                            popper-class="global_select_popper" style="margin-left: 10px;" @command="yearFinish">
                            <el-button size="small" type="primary" class="button_98px">完成项目</el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="(year,index) in years" :key="index" :command="year" :disabled="finish_info.indexOf(year)!==-1">{{year}}年度</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>-->
                    </div>
                </div>
            </div>
            <!--编辑项目-->
            <editProject v-if="dialogType==='edit'" :detail="detail" :prop_years="years" :enter="enter"
                         @change="detailChange"
                         @back="back"></editProject>
            <!--录入月报表-->
            <addMonthForm ref="addMonthForm" @refresh="refresh" :yearFinish="detail.year_finish"
                          :all_invest_num="detail.all_invest_num" @editYearFinish="editYearFinish"></addMonthForm>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import computedData from '@/mixins/computedData.js';
import monthFormDetail from '@c/project/month_form/month_form_detail.vue';
import editProject from '@c/project/edit_project.vue';
import addMonthForm from '@c/project/month_form/add_month_form.vue';
import editMonthForm from '@c/project/month_form/edit_month_form.vue';
import dingDisk from '@/utils/ding_disk';
import myPublic from '@/utils/public';
import paymentDetail from '@c/project/payment/payment_detail.vue';
import checkItemDetail from '@c/project/check_item_detail/check_item_detail.vue';
import addPayment from "@c/project/payment/add_payment.vue";
import editPayment from '@c/project/payment/edit_payment.vue';
import fileListComp from '@/components/file/index.vue'

export default {
    mixins: [computedData],
    components: {
        monthFormDetail,
        editProject,
        addMonthForm,
        paymentDetail,
        editMonthForm,
        checkItemDetail,
        addPayment,
        editPayment,
        fileListComp
    },
    props: ['project_detail', 'enter'],//enter:1表示从市项目管理点进来,enter:2表示从统计分析列表中点进来
    data() {
        return {
            show: false, dialogType: 'detail',//detail项目详情,edit编辑项目
            years: [], detail: {}, formList: [], finish_info: [],//项目已完成年度,年度集合
            fileList: [
                {value: 'before', files: []},
                {value: 'process', files: []},
                {value: 'check', files: []},
                {value: 'monthly', files: []},
                {value: 'log', files: []},
            ], project_brfore_num: undefined, file_change: false,//附件是否变更,如果有则执行managementEdit();
            payment: [],//项目拨付款
            checkItems: [], checkItems2: [],//checkItems援青干部日常检查记录 , checkItems2第三方检查日志
            monthFiles: [],//项目月报附件集合
            isChange: [
                {value: 1, label: '未变更'},
                {value: 2, label: '已变更'}
            ],
            reportList: [],
            money_total: 0,//项目已拨付资金总额
            money_rate: 0,//项目已拨付资金占比(已拨付总金额 / 实际投资金额)
            mark: '',//项目拨付款识别用的,0,1,2等数字为编辑的项目拨付款index,-1为新建
        };
    },
    computed: {
        shi_project_project_power() {
            return this.$store.state.user.current_user.shi_project_project_power;
        },
        statistics_power() {
            return this.$store.state.user.current_user.statistics_power;
        }
    },
    methods: {
        open() {
            this.dialogType = 'detail';
            this.projectDetail();
            this.show = true;
        },
        back() {
            this.dialogType = 'detail';
        },
        //编辑月报后重新刷新月报列表和项目拨付款列表
        refresh() {
            this.formLists();
            this.appLists();
        },
        //保存项目管理附件和立项前期费用
        managementEdit() {
            let param = {
                project_id: this.detail.project_id,
                project_management: JSON.stringify(this.fileList)
            };
            if (this.project_brfore_num !== null && this.project_brfore_num !== undefined && this.project_brfore_num !== '') {
                param.project_brfore_num = this.project_brfore_num * 10000;
            } else {
                param.project_brfore_num = '';
            }
            if (JSON.stringify(this.fileList) === this.detail.project_management && param.project_brfore_num === this.detail.project_brfore_num) {
                // this.$message.error('项目管理相关信息未修改无法保存!');
                return;
            }
            api.project.managementEdit(param).then(res => {
                if (res.data.state == 1) {
                    this.$message({
                        message: '项目管理信息保存成功',
                        type: 'success'
                    });
                    this.detail.project_management = param.project_management;
                    this.detail.project_brfore_num = param.project_brfore_num;
                }
            });
        },
        projectDetail() {
            this.detail = {};
            this.years = [];
            this.finish_info = [];
            api.project.projectDetail({project_id: this.project_detail.project_id}).then(res => {
                if (res.data) {
                    let detail = res.data.rows || {};
                    if (!detail.contact_group) detail.contact_group = {};
                    if (!detail.contact_group_fuze) detail.contact_group_fuze = {};
                    if (detail.finish_info) {
                        detail.finish_info.forEach(item => {
                            if (item.finish_year) this.finish_info.push(Number(item.finish_year.slice(0, 4)));
                        });
                    }
                    if (detail.all_invest_num) {
                        detail.all_invest_num = Number(detail.all_invest_num) / 10000;
                    }
                    if (detail.real_num) {
                        detail.real_num = Number(detail.real_num) / 10000;
                    }
                    if (detail.year_invest_num) {
                        detail.year_invest_num = JSON.parse(detail.year_invest_num);
                        for (let year in detail.year_invest_num) {
                            detail.year_invest_num[year] = Number(detail.year_invest_num[year]) / 10000;
                        }
                    } else {
                        detail.year_invest_num = {};
                    }
                    if (detail.child_project_info) {
                        detail.child_project_info = JSON.parse(detail.child_project_info);
                        detail.child_project_info.forEach(child => {
                            child.child_all_invest_num = Number(child.child_all_invest_num) / 10000;
                            for (let year in child.child_year_invest_num) {
                                child.child_year_invest_num[year] = Number(child.child_year_invest_num[year]) / 10000;
                            }
                        });
                    } else {
                        detail.child_project_info = [];
                    }
                    if (myPublic.isJsonString(detail.project_management)) {
                        this.fileList = JSON.parse(detail.project_management);
                    } else {
                        this.fileList = [
                            {value: 'before', files: []},
                            {value: 'process', files: []},
                            {value: 'check', files: []},
                            {value: 'monthly', files: []},
                            {value: 'log', files: []},
                        ];
                        detail.project_management = JSON.stringify([
                            {value: 'before', files: []},
                            {value: 'process', files: []},
                            {value: 'check', files: []},
                            {value: 'monthly', files: []},
                            {value: 'log', files: []},
                        ]);
                    }
                    if (detail.project_brfore_num !== null && detail.project_brfore_num !== undefined && detail.project_brfore_num !== '') {
                        this.project_brfore_num = Number(detail.project_brfore_num) / 10000;
                    } else {
                        this.project_brfore_num = undefined;
                        detail.project_brfore_num = '';
                    }
                    let regN = /\n/g;
                    let regR = /\r/g;
                    //项目概况
                    if (detail.project_profile) {
                        detail.new_project_profile = detail.project_profile.replace(regN, "<br />").replace(regR, "<br />");
                    }
                    //项目审计
                    if (detail.audit) {
                        detail.new_audit = detail.audit.replace(regN, "<br />").replace(regR, "<br />");
                    }
                    //项目检查
                    if (detail.check) {
                        detail.new_check = detail.check.replace(regN, "<br />").replace(regR, "<br />");
                    }
                    //项目评估
                    if (detail.assess) {
                        detail.new_assess = detail.assess.replace(regN, "<br />").replace(regR, "<br />");
                    }
                    //项目变更情况
                    if (!detail.is_change) {
                        detail.is_change = 1;
                    }
                    //项目变更附件
                    if (myPublic.isJsonString(detail.change_files)) {
                        detail.change_files = JSON.parse(detail.change_files);
                    } else {
                        detail.change_files = [];
                    }
                    // console.log(detail,'detail')
                    this.detail = detail;
                    if (detail.start_time && detail.end_time) {
                        detail.time = [
                            detail.start_time.slice(0, 4) + '-' + detail.start_time.slice(5, 7),
                            detail.end_time.slice(0, 4) + '-' + detail.end_time.slice(5, 7)
                        ];
                        let start = detail.start_time.slice(0, 4);
                        let end = detail.end_time.slice(0, 4);
                        for (let i = start; i <= end; i++) {
                            this.years.push(Number(i));
                        }
                    }
                    this.appLists();
                    this.formLists();
                    this.getCheckItems();
                    this.getCheckItems2();
                    this.getReportList();
                }
            });
        },
        //新增月报
        addMonthForm() {
            this.$refs['addMonthForm'].show = true;
            this.$refs['addMonthForm'].project_detail = this.detail;
            this.$refs['addMonthForm'].formList = this.formList;
        },
        //月报列表
        formLists() {
            this.formList = [];
            this.monthFiles = [];
            let param = {project_id: this.project_detail.project_id, page: 1, pagesize: 200};
            api.project.formLists(param).then(res => {
                if (res.data) {
                    this.formList = res.data.lists || [];
                    this.formList.forEach(item => {
                        if (myPublic.isJsonString(item.project_file_ids)) {
                            item.files = JSON.parse(item.project_file_ids);
                        } else {
                            item.files = [];
                        }
                        this.monthFiles.push(...item.files);
                        if (item.implementation_before && new Date(item.month).getFullYear() === new Date().getFullYear()) {
                            let implementation_before = item.implementation_before.split(',');
                            item.implementation_before = implementation_before.map(Number);
                        } else {
                            item.implementation_before = [];
                        }
                        if (item.implementation_after && new Date(item.month).getFullYear() === new Date().getFullYear()) {
                            let implementation_after = item.implementation_after.split(',');
                            item.implementation_after = implementation_after.map(Number);
                        } else {
                            item.implementation_after = [];
                        }
                    });
                }
            });
        },
        //项目拨付款列表
        appLists() {
            this.payment = [];
            api.project.appLists({project_id: this.detail.project_id, page: 1, pagesize: 200}).then(res => {
                if (res.data) {
                    this.payment = res.data.lists || [];
                    this.money_total = 0;
                    this.money_rate = 0;
                    if (this.payment.length) {
                        this.payment.forEach(item => {
                            if (!isNaN(Number(item.app_money))) {
                                this.money_total = this.money_total + Number(item.app_money);
                            }
                            if (myPublic.isJsonString(item.app_file_ids)) {
                                item.app_file_ids = JSON.parse(item.app_file_ids);
                            } else {
                                item.app_file_ids = [];
                            }
                            if (item.app_money) {
                                item.app_money = Number(item.app_money) / 10000;
                            }
                        });
                        this.money_total = this.money_total / 10000;
                        this.money_rate = ((this.money_total / this.detail.real_num) * 100).toFixed(2);
                    }
                }
            });
        },
        //日常检查记录列表
        getCheckItems() {
            this.checkItems = [];
            api.project.getCheckItems({
                project_id: this.detail.project_id,
                page: 1,
                pagesize: 200,
                type: 1
            }).then(res => {
                if (res.data) {
                    this.checkItems = res.data.lists || [];
                    this.checkItems.forEach(item => {
                        if (myPublic.isJsonString(item.address)) {
                            item.address_obj = JSON.parse(item.address);
                        }
                        if (item.create_time) {
                            item.show_time = item.create_time.slice(0, -3);
                        }
                        item.show_name = '无';
                        if (item.user_info.nickname) {
                            item.show_name = item.user_info.nickname;
                        }
                    });
                }
            });
        },
        getCheckItems2() {
            this.checkItems2 = [];
            api.project.getCheckItems({
                project_id: this.detail.project_id,
                page: 1,
                pagesize: 200,
                type: 2
            }).then(res => {
                if (res.data) {
                    this.checkItems2 = res.data.lists || [];
                    this.checkItems2.forEach(item => {
                        if (myPublic.isJsonString(item.address)) {
                            item.address_obj = JSON.parse(item.address);
                        }
                        if (item.create_time) {
                            item.show_time = item.create_time.slice(0, -3);
                        }
                        item.show_name = '无';
                        if (item.user_info.nickname) {
                            item.show_name = item.user_info.nickname;
                        }
                    });
                }
            });
        },
        delCheck(id) {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, '确定要删除该检查日志吗?'),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        api.project.checkItemDel({id: id}).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: '检查日志删除成功',
                                    type: 'success'
                                });
                                this.click_del = true;
                                this.open();
                            }
                        });
                        done();
                    } else {
                        done();
                    }
                }
            }).catch(() => {

            });
        },
        //上传报告列表
        getReportList() {
            this.reportList = [];
            let param = {
                page: 1,
                pagesize: 999,
                project_id: this.detail.project_id
            };
            api.project.reportList(param).then(res => {
                if (res.data) {
                    let list = res.data.lists || [];
                    list.forEach(item => {
                        if (myPublic.isJsonString(item.file_ids)) {
                            item.file_ids = JSON.parse(item.file_ids);
                        } else {
                            item.file_ids = [];
                        }
                    });
                    this.reportList = list;
                }
            });
        },
        yearFinish(year) {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, `确定要完成${year}年度项目吗?`),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        api.project.yearFinish({
                            project_id: this.project_detail.project_id,
                            year: year
                        }).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: `${year}年度完成成功`,
                                    type: 'success'
                                });
                                this.finish_info.push(year);
                            }
                        });
                        done();
                    } else {
                        done();
                    }
                }
            }).catch(() => {

            });
        },
        toMonthFormDetail(form_id) {
            this.$refs['monthFormDetail'].show = true;
            this.$refs['monthFormDetail'].form_id = form_id;
        },
        //编辑项目后传值回来
        detailChange(years, detail) {
            this.years = years;
            this.detail = detail;
            this.dialogType = 'detail';
            // this.tab = 'detail';
        },
        //终止项目
        stopProject(id) {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, '确定要终止该项目吗?'),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        api.project.stopProject({project_id: id}).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: '项目终止成功',
                                    type: 'success'
                                });
                                this.$emit('refresh');
                            }
                        });
                        done();
                    } else {
                        done();
                    }
                }
            }).catch(() => {

            });
        },
        deleteProject(id) {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, '确定要删除该项目吗?'),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        api.project.deleteProject({project_id: id}).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: '项目删除成功',
                                    type: 'success'
                                });
                                this.$emit('refresh');
                            }
                        });
                        done();
                    } else {
                        done();
                    }
                }
            }).catch(() => {

            });
        },
        //上传附件
        updateFile(item) {
            dingDisk.dingDiskUpdate(res => {
                item.files = [...item.files, ...res.data];
                this.file_change = true;
            });
        },
        //预览附件
        previewFile(file_info) {
            dingDisk.previewFile(file_info);
        },
        //项目管理下的附件改名
        editNameBackManage(list, item) {
            this.$set(item, 'files', list);
            this.managementEdit();
        },
        //选择项目已变更或未变更
        chooseChange(value) {
            if (this.detail.is_change === value) return;
            this.detail.is_change = value;
        },
        //删除项目变更附件
        delChangeFile(file_index) {
            this.detail.change_files.splice(file_index, 1);
        },
        //上传项目变更附件
        updateChangeFile() {
            dingDisk.dingDiskUpdate(res => {
                this.detail.change_files = [...this.detail.change_files, ...res.data];
            });
        },
        //项目变更下的附件修改名字
        editNameBackChange(file_list) {
            this.detail.change_files = file_list;
        },
        //项目变更后执行的编辑项目api
        changeApi() {
            let param = {
                project_id: this.detail.project_id,
                project_name: this.detail.project_name,
                area: this.detail.area,
                address: this.detail.address,
                all_invest_num: this.detail.all_invest_num * 10000,
                year_invest_num: JSON.parse(JSON.stringify(this.detail.year_invest_num)),
                start_time: this.detail.time[0],
                end_time: this.detail.time[1],
                build_quality: this.detail.build_quality,
                project_quality: this.detail.project_quality,
                project_genre: this.detail.project_genre,
                project_profile: this.detail.project_profile,
                contact_group: this.detail.contact_group.department_id,
                contact_group_fuze: this.detail.contact_group_fuze.userid,
                project_unit: this.detail.project_unit,
                project_fuze: this.detail.project_fuze,
                fuze_phone: this.detail.fuze_phone,
                is_change: this.detail.is_change,
                change_files: JSON.stringify(this.detail.change_files),
                fgld: this.detail.fgld || '',
                fgld_phone: this.detail.fgld_phone || '',
            };
            if (this.detail.real_num === '' || this.detail.real_num === undefined || this.detail.real_num === null) {
                param.real_num = param.all_invest_num;
            } else {
                param.real_num = this.detail.real_num * 10000;
            }
            for (let year in param.year_invest_num) {
                param.year_invest_num[year] = Number(param.year_invest_num[year]) * 10000;
            }
            param.year_invest_num = JSON.stringify(param.year_invest_num);
            param.child_project_info = [];
            let child_project_info = JSON.parse(JSON.stringify(this.detail.child_project_info));
            child_project_info.forEach(item => {
                item.child_all_invest_num = Number(item.child_all_invest_num) * 10000;
                for (let year in item.child_year_invest_num) {
                    item.child_year_invest_num[year] = Number(item.child_year_invest_num[year]) * 10000;
                }
                param.child_project_info.push({
                    child_name: item.child_name,
                    child_all_invest_num: item.child_all_invest_num,
                    child_year_invest_num: item.child_year_invest_num
                });
            });
            param.child_project_info = JSON.stringify(param.child_project_info);
            api.project.addProject(param).then(res => {
                if (res.data.state == 1) {
                    this.$message({
                        message: '变更项目成功',
                        type: 'success'
                    });
                }
            });
        },
        //修改本年度项目完工情况
        editYearFinish(val) {
            this.detail.year_finish = JSON.parse(JSON.stringify(val));
        },
        //添加项目拨付款
        addApp(param) {
            param.app_file_ids = JSON.stringify(param.app_file_ids);
            param.app_money = Number(param.app_money) * 10000;
            api.project.addApp({
                ...param,
                project_id: this.detail.project_id,
            }).then(res => {
                if (res.data.state == 1) {
                    this.$message({
                        message: `${param.app_id ? '编辑' : '添加'}项目拨付款成功`,
                        type: 'success'
                    });
                    this.mark = '';
                    this.appLists();
                }
            });
        }
    },
    watch: {
        file_change: {
            handler: function (val) {
                if (val) {
                    this.managementEdit();
                    this.file_change = false;
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="less">
div.project_detail_dialog {
    .el-dialog {
        height: 90%;

        .detail_box {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .tab {
        display: flex;
        padding-left: 30px;
        border-bottom: 1px solid #e4e7ec;

        .tab_item {
            width: 68px;
            line-height: 50px;
            margin-right: 40px;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s;
            text-align: center;

            &.active {
                color: #43e0a7;
                box-shadow: 0 -4px inset #43e0a7;
            }
        }
    }

    .detail_item {
        .money_total {
            padding: 10px 0 0 10px;
        }
    }

    .detail_item.items {
        .item:first-of-type {
            /*&.long {*/
            /*box-shadow: none !important;*/

            /*.left {*/
            /*box-shadow: 0 1px #e4e7ec !important;*/
            /*}*/
            /*}*/
        }
    }

    .project_manage {
        background: #fafafa;
        margin: 0 30px 20px 30px;
        border-radius: 5px;
        border: 1px solid #ededee;
        padding: 10px 0;

        .files_items.row {
            padding: 0;

            .item {
                display: flex;
                align-items: flex-start;
                line-height: 30px;

                .left {
                    flex: 0 0 120px;
                    padding-left: 20px;
                }

                .right {
                    flex: 1;
                    padding-right: 20px;

                    .text {
                        padding-top: 6px;
                    }
                }

                button.el-button.el-button--text {
                    margin-left: 0 !important;
                }

                div.el-input-number.el-input-number--mini {
                    width: 10%;

                    input.el-input__inner {
                        padding: 0;
                        font-size: 14px;
                        border: none;
                        background: #fafafa;
                    }
                }
            }
        }

        .btn {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .report_list {
        .files {
            width: 100%;
            text-align: left;
            padding: 10px 0;

            .file {
                line-height: 16px;
                margin-bottom: 10px;
                transition: color 0.3s;
                cursor: pointer;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:hover {
                    color: #3396fb;
                }
            }
        }
    }
}
</style>